@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:100,400,500,300,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&subset=cyrillic);
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @media (min-width: 768px) {
    .container {
      width: 720px; } }
  @media (min-width: 992px) {
    .container {
      width: 940px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1140px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0; }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: 0;
  margin-right: 0; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.3333333333%; }

.col-xs-2 {
  width: 16.6666666667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.3333333333%; }

.col-xs-5 {
  width: 41.6666666667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.3333333333%; }

.col-xs-8 {
  width: 66.6666666667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.3333333333%; }

.col-xs-11 {
  width: 91.6666666667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.3333333333%; }

.col-xs-pull-2 {
  right: 16.6666666667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.3333333333%; }

.col-xs-pull-5 {
  right: 41.6666666667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.3333333333%; }

.col-xs-pull-8 {
  right: 66.6666666667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.3333333333%; }

.col-xs-pull-11 {
  right: 91.6666666667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.3333333333%; }

.col-xs-push-2 {
  left: 16.6666666667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.3333333333%; }

.col-xs-push-5 {
  left: 41.6666666667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.3333333333%; }

.col-xs-push-8 {
  left: 66.6666666667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.3333333333%; }

.col-xs-push-11 {
  left: 91.6666666667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.3333333333%; }

.col-xs-offset-2 {
  margin-left: 16.6666666667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.3333333333%; }

.col-xs-offset-5 {
  margin-left: 41.6666666667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.3333333333%; }

.col-xs-offset-8 {
  margin-left: 66.6666666667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.3333333333%; }

.col-xs-offset-11 {
  margin-left: 91.6666666667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.3333333333%; }
  .col-sm-2 {
    width: 16.6666666667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.3333333333%; }
  .col-sm-5 {
    width: 41.6666666667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.3333333333%; }
  .col-sm-8 {
    width: 66.6666666667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.3333333333%; }
  .col-sm-11 {
    width: 91.6666666667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.3333333333%; }
  .col-sm-pull-2 {
    right: 16.6666666667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.3333333333%; }
  .col-sm-pull-5 {
    right: 41.6666666667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.3333333333%; }
  .col-sm-pull-8 {
    right: 66.6666666667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.3333333333%; }
  .col-sm-pull-11 {
    right: 91.6666666667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.3333333333%; }
  .col-sm-push-2 {
    left: 16.6666666667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.3333333333%; }
  .col-sm-push-5 {
    left: 41.6666666667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.3333333333%; }
  .col-sm-push-8 {
    left: 66.6666666667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.3333333333%; }
  .col-sm-push-11 {
    left: 91.6666666667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%; }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%; }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%; }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%; }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.3333333333%; }
  .col-md-2 {
    width: 16.6666666667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.3333333333%; }
  .col-md-5 {
    width: 41.6666666667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.3333333333%; }
  .col-md-8 {
    width: 66.6666666667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.3333333333%; }
  .col-md-11 {
    width: 91.6666666667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.3333333333%; }
  .col-md-pull-2 {
    right: 16.6666666667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.3333333333%; }
  .col-md-pull-5 {
    right: 41.6666666667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.3333333333%; }
  .col-md-pull-8 {
    right: 66.6666666667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.3333333333%; }
  .col-md-pull-11 {
    right: 91.6666666667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.3333333333%; }
  .col-md-push-2 {
    left: 16.6666666667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.3333333333%; }
  .col-md-push-5 {
    left: 41.6666666667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.3333333333%; }
  .col-md-push-8 {
    left: 66.6666666667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.3333333333%; }
  .col-md-push-11 {
    left: 91.6666666667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.3333333333%; }
  .col-md-offset-2 {
    margin-left: 16.6666666667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.3333333333%; }
  .col-md-offset-5 {
    margin-left: 41.6666666667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.3333333333%; }
  .col-md-offset-8 {
    margin-left: 66.6666666667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.3333333333%; }
  .col-md-offset-11 {
    margin-left: 91.6666666667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.3333333333%; }
  .col-lg-2 {
    width: 16.6666666667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.3333333333%; }
  .col-lg-5 {
    width: 41.6666666667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.3333333333%; }
  .col-lg-8 {
    width: 66.6666666667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.3333333333%; }
  .col-lg-11 {
    width: 91.6666666667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.3333333333%; }
  .col-lg-pull-2 {
    right: 16.6666666667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.3333333333%; }
  .col-lg-pull-5 {
    right: 41.6666666667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.3333333333%; }
  .col-lg-pull-8 {
    right: 66.6666666667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.3333333333%; }
  .col-lg-pull-11 {
    right: 91.6666666667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.3333333333%; }
  .col-lg-push-2 {
    left: 16.6666666667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.3333333333%; }
  .col-lg-push-5 {
    left: 41.6666666667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.3333333333%; }
  .col-lg-push-8 {
    left: 66.6666666667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.3333333333%; }
  .col-lg-push-11 {
    left: 91.6666666667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%; }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%; }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%; }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%; }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .nav:before, .nav:after {
    content: " ";
    display: table; }
  .nav:after {
    clear: both; }
  .nav > li {
    position: relative;
    display: block; }
    .nav > li > a {
      position: relative;
      display: block;
      padding: 10px 15px; }
      .nav > li > a:hover, .nav > li > a:focus {
        text-decoration: none;
        background-color: #eeeeee; }
    .nav > li.disabled > a {
      color: #777777; }
      .nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
        color: #777777;
        text-decoration: none;
        background-color: transparent;
        cursor: not-allowed; }
  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: #eeeeee;
    border-color: #27ae60; }
  .nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .nav > li > a > img {
    max-width: none; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px; }
    .nav-tabs > li > a {
      margin-right: 2px;
      line-height: 1.428571429;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0; }
      .nav-tabs > li > a:hover {
        border-color: #eeeeee #eeeeee #ddd; }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      color: #555555;
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
      cursor: default; }

.nav-pills > li {
  float: left; }
  .nav-pills > li > a {
    border-radius: 4px; }
  .nav-pills > li + li {
    margin-left: 2px; }
  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #27ae60; }

.nav-stacked > li {
  float: none; }
  .nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0; }

.nav-justified, .nav-tabs.nav-justified {
  width: 100%; }
  .nav-justified > li, .nav-tabs.nav-justified > li {
    float: none; }
    .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
      text-align: center;
      margin-bottom: 5px; }
  .nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto; }
  @media (min-width: 768px) {
    .nav-justified > li, .nav-tabs.nav-justified > li {
      display: table-cell;
      width: 1%; }
      .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
        margin-bottom: 0; } }

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0; }
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 4px; }
  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
  .nav-tabs-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #ddd; }
  @media (min-width: 768px) {
    .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0; }
    .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
    .nav-tabs-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:focus {
      border-bottom-color: #fff; } }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    display: none; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

html,
body {
  height: 100%; }

body {
  font: 12px/18px Arial, sans-serif;
  overflow-y: scroll; }

body.fixed {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed; }

/* add fonts
---------------------*/
@font-face {
  font-family: 'intro_regular';
  src: url("../fonts/intro-webfont.eot");
  src: url("../fonts/intro-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/intro-webfont.woff2") format("woff2"), url("../fonts/intro-webfont.woff") format("woff"), url("../fonts/intro-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'intro_inlineregular';
  src: url("../fonts/intro_inline-webfont.eot");
  src: url("../fonts/intro_inline-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/intro_inline-webfont.woff2") format("woff2"), url("../fonts/intro_inline-webfont.woff") format("woff"), url("../fonts/intro_inline-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'als_rublregular';
  src: url("../fonts/rouble_sign-webfont.eot");
  src: url("../fonts/rouble_sign-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/rouble_sign-webfont.woff2") format("woff2"), url("../fonts/rouble_sign-webfont.woff") format("woff"), url("../fonts/rouble_sign-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'clear_sans_lightregular';
  src: url("../fonts/clearsans-light-webfont.eot");
  src: url("../fonts/clearsans-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/clearsans-light-webfont.woff2") format("woff2"), url("../fonts/clearsans-light-webfont.woff") format("woff"), url("../fonts/clearsans-light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'clear_sans_mediumregular';
  src: url("../fonts/clearsans-medium-webfont.eot");
  src: url("../fonts/clearsans-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/clearsans-medium-webfont.woff2") format("woff2"), url("../fonts/clearsans-medium-webfont.woff") format("woff"), url("../fonts/clearsans-medium-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'clear_sansregular';
  src: url("../fonts/clearsans-regular-webfont.eot");
  src: url("../fonts/clearsans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/clearsans-regular-webfont.woff2") format("woff2"), url("../fonts/clearsans-regular-webfont.woff") format("woff"), url("../fonts/clearsans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?xl4hff");
  src: url("../fonts/icomoon.eot?xl4hff#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?xl4hff") format("truetype"), url("../fonts/icomoon.woff?xl4hff") format("woff"), url("../fonts/icomoon.svg?xl4hff#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon-font:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-font-after:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-bread-back:before {
  content: "\e90a"; }

.icon-bread-next:after {
  content: "\e90c"; }

.icon-loopa:before {
  content: "\e909"; }

.icon-play:before {
  content: "\e908"; }

.icon-copy:before {
  content: "\e907"; }

.icon-close:before {
  content: "\e901"; }

.icon-down-cur:before {
  content: "\e902"; }

.icon-left-cur:before {
  content: "\e903"; }

.icon-right-cur:before {
  content: "\e904"; }

.icon-youtube:before {
  content: "\e905"; }

.icon-menu:before {
  content: "\e913"; }

.icon-play3:before {
  content: "\e90b"; }

.icon-facebook2:before {
  content: "\e900"; }

.icon-instagram:before {
  content: "\e906"; }

.icon-vk:before {
  content: "\e911"; }

/* for 5 & 7 col
----------------------*/
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15,
.col-xs-17,
.col-sm-17,
.col-md-17,
.col-lg-17 {
  position: relative;
  min-height: 1px; }

.col-xs-15 {
  width: 20%;
  float: left; }

.col-xs-17 {
  width: 14.2857%;
  float: left; }

@media (min-width: 480px) {
  .col-sm-15 {
    width: 20%;
    float: left; }
  .col-sm-17 {
    width: 14.2857%;
    float: left; } }

@media (min-width: 768px) {
  .col-md-15 {
    width: 20%;
    float: left; }
  .col-md-17 {
    width: 14.2857%;
    float: left; } }

@media (min-width: 992px) {
  .col-lg-15 {
    width: 20%;
    float: left; }
  .col-lg-17 {
    width: 14.2857%;
    float: left; } }

/* for less shotcode
---------------------*/
.radius3 {
  border-radius: 3px; }

.radius6 {
  border-radius: 6px; }

.radius50 {
  border-radius: 50%; }

.duration01 {
  transition-duration: 0.1s; }

.duration03 {
  transition-duration: 0.3s; }

.duration06 {
  transition-duration: 0.6s; }

/*---------------------*/
.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%; }

.wrap {
  margin: 0 auto;
  min-width: 320px;
  max-width: 1280px;
  padding: 0 10px; }

/* Header
-----------------------------------------------------------------------------*/
.header {
  height: 60px;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100; }

.header.active {
  background: black; }

.header.nofix {
  position: static; }

.header .logo {
  font-size: 0;
  color: white;
  font-family: 'intro_inlineregular';
  float: left;
  margin-top: 7px;
  width: 223px; }

.header .logo img {
  display: inline-block;
  vertical-align: top;
  width: 100%; }

.header .logo span {
  font-size: 18px;
  display: inline-block;
  vertical-align: top;
  line-height: 40px;
  padding-left: 9px;
  text-indent: -9999px; }

.header .menu {
  font-family: 'intro_regular';
  font-size: 0;
  float: left;
  line-height: 60px;
  padding-left: 78px; }

.header .menu .mobile-menu-bt {
  display: none;
  cursor: pointer; }

.header .menu .mobile-menu-bt:before {
  color: white;
  font-size: 20px;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px; }

.header .menu .mobile-menu-bt.icon-menu-png {
  background: url("../images/mobile_menu.png") no-repeat scroll;
  width: 25px;
  height: 21px;
  margin-top: 20px; }

.header .menu ul li {
  display: inline-block;
  vertical-align: top; }

.header .menu ul li a {
  display: block;
  text-decoration: none;
  color: #e2d29d;
  font-size: 16px;
  padding: 0 15px;
  height: 60px;
  transition-duration: 0.3s; }

.header .menu ul li a:hover,
.header .menu ul li.active a {
  background: #dac98d;
  color: white; }

.header .phone {
  float: right;
  margin-right: 76px; }

.header .phone a {
  font-family: 'intro_regular';
  color: white;
  font-size: 20px;
  text-decoration: none;
  line-height: 60px;
  transition-duration: 0.3s; }

.header .phone a:hover {
  opacity: 0.8; }

.header .lang-select {
  margin-top: 20px;
  float: right; }

.header .lang-select .ru-link,
.header .lang-select .eng-link {
  font-family: 'clear_sansregular';
  float: left;
  font-size: 13px;
  line-height: 20px;
  color: white;
  text-decoration: none;
  text-transform: uppercase; }

.header .lang-select .ru-link:hover,
.header .lang-select .eng-link:hover {
  text-decoration: underline; }

.header .lang-select .lang-tab {
  float: left;
  width: 40px;
  height: 20px;
  background: #272727;
  margin: 0 7px;
  border-radius: 10px; }

.header .lang-select .lang-tab .ru {
  width: 16px;
  height: 16px;
  float: left;
  margin: 2px; }

.header .lang-select .lang-tab .ru.active {
  background: url("../images/flag.png") no-repeat scroll left top; }

.header .lang-select .lang-tab .eng {
  width: 16px;
  height: 16px;
  float: right;
  margin: 2px; }

.header .lang-select .lang-tab .eng.active {
  background: url("../images/flag.png") no-repeat scroll left bottom; }

/* Middle
-----------------------------------------------------------------------------*/
.content {
  padding: 0 0 90px; }

.section-2 .title,
.section-2 h2,
.section-3 .title,
.section-4 .title,
.section-5 .title,
.section-6 .title,
.additional-services-box .title {
  font-family: 'intro_regular';
  color: #d1c391;
  font-size: 30px;
  line-height: normal;
  text-align: center;
  font-weight: normal; }

.section-2 .title span {
  color: #5d5d5d; }

.section-2 .title span i {
  font-family: 'als_rublregular';
  font-style: normal; }

.section-1 {
  background: url("../images/bg-main-section-1.jpg") no-repeat fixed center top white;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  min-width: 320px; }

.section-video {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px; }

.main-info {
  color: white;
  display: table-cell;
  vertical-align: middle;
  padding-top: 100px;
  padding-bottom: 20px;
  position: relative; }

.main-info .title,
.main-info h1 {
  color: white;
  font-family: 'intro_inlineregular';
  font-size: 45px;
  line-height: 45px;
  margin: 0 0 20px -2px;
  font-weight: normal; }

.main-info p {
  font-family: 'clear_sansregular';
  font-size: 20px;
  line-height: 30px; }

.services-box {
  position: absolute;
  left: 0;
  bottom: 90px;
  width: 100%; }

.services-box .title {
  font-family: 'intro_regular';
  color: white;
  font-size: 24px;
  line-height: normal;
  margin-bottom: 32px;
  margin-left: 20px; }

.services-menu li {
  list-style: none;
  float: left;
  box-sizing: border-box; }

.services-menu li a {
  position: relative;
  z-index: 9;
  font-family: 'clear_sans_mediumregular';
  font-size: 20px;
  display: block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: white;
  text-shadow: 0 1px 1px #584F2F;
  margin: 0 5px 10px;
  border-radius: 3px;
  text-decoration: none;
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b); }

.services-menu li a:first-child {
  margin-left: 0; }

.services-menu li a:last-child {
  margin-right: 0; }

.services-menu li a .serv-icon {
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  left: 20px;
  top: 6px; }

.home .services-menu li a .serv-icon {
  top: 10px; }

.home .services-menu li a .serv-icon img {
  vertical-align: super; }

.services-menu li a:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s linear;
  border-radius: 3px;
  background: #ab9c5b; }

.services-menu li a:hover:before {
  opacity: 1; }

.icon-down-cur {
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  text-align: center;
  color: #dac98d;
  background: white;
  margin-bottom: -30px;
  line-height: 64px;
  border-radius: 50%;
  z-index: 50; }

.section-2 {
  padding: 52px 0 60px 0;
  position: relative; }

.section-2 h2 {
  padding-bottom: 37px; }

.section-2 .intro {
  text-align: center;
  font-family: 'clear_sansregular';
  font-size: 20px;
  line-height: 26px;
  max-width: 650px;
  margin: 0 auto;
  padding: 0 0 40px; }

.section-2 .intro a,
.section-2 .intro span {
  cursor: pointer;
  text-transform: uppercase;
  color: #d1c391;
  text-decoration: underline; }

.section-2 .intro a:hover,
.section-2 .intro span:hover {
  text-decoration: none; }

.section-2 .intro span {
  text-decoration: none;
  border-bottom: 1px dashed #d1c391;
  display: inline-block;
  line-height: 20px; }

.section-2 .intro span:hover {
  border-bottom: none; }

.section-2 .intro h2 {
  padding-bottom: 20px; }

.section-2 .show-all {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #5d5d5d;
  font-family: 'intro_regular';
  font-size: 16px;
  width: 300px;
  height: 60px;
  border: 1px solid #4b4b4b;
  border-radius: 3px;
  margin: 0 auto;
  text-align: center;
  line-height: 60px;
  transition-duration: 0.3s; }

.section-2 .show-all:hover {
  opacity: 0.7; }

.programs-menu {
  margin-bottom: 41px;
  border-bottom: none; }

.programs-menu li {
  list-style-type: none;
  min-height: 60px; }

.programs-menu .tab-bt {
  cursor: pointer;
  font-family: 'clear_sans_mediumregular';
  font-size: 19px;
  line-height: 50px;
  position: relative;
  text-align: center;
  margin-bottom: 7px;
  transition-duration: 0.3s;
  display: block;
  color: #000;
  text-decoration: none; }

.programs-menu .tab-bt:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 7px;
  width: 100%;
  height: 2px;
  background: #dac98d; }

.programs-menu .tab-bt:hover {
  opacity: 0.6; }

.programs-menu .active .tab-bt {
  color: white;
  line-height: 50px;
  height: 50px;
  border-radius: 3px;
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b); }

.programs-menu .active .tab-bt:after {
  display: none; }

.programs-menu .active .tab-bt:hover {
  opacity: 1; }

.programs-menu .kvo {
  text-align: center;
  font-family: 'clear_sans_mediumregular';
  font-size: 16px; }

.programs-list {
  margin-bottom: 40px; }

.programs-list .product-content > div {
  padding: 0 5px;
  box-sizing: border-box; }

.programs-list .product-content > div:nth-child(4n-3) {
  padding-left: 0; }

.programs-list .product-content > div:nth-child(4n) {
  padding-right: 0; }

.programm-box {
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 3px;
  transition-duration: 0.3s; }

.programm-box:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
  transition-duration: 0.3s; }

.programm-box .pic img {
  display: block; }

.programm-box .text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 2;
  padding: 0 30px;
  box-sizing: border-box; }

.programm-box .text .tit {
  display: block;
  font-family: 'clear_sansregular';
  font-size: 30px;
  line-height: 35px;
  color: white;
  margin-bottom: 17px; }

.programm-box .text .tit a {
  text-decoration: none;
  color: #fff; }

.programm-box .text .opis {
  display: block;
  opacity: 0;
  height: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;
  color: white;
  transition-duration: 0.3s; }

.programm-box .text .opis .icon-play3 {
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  padding: 19px 0;
  display: block;
  font-family: 'clear_sansregular';
  margin-bottom: 29px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); }

.programm-box .text .opis .icon-play3:before {
  border-radius: 3px;
  background: #bb3d3d;
  width: 30px;
  height: 30px;
  font-size: 16px;
  color: white;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 11px; }

.programm-box .text .price {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  display: block;
  font-size: 30px;
  line-height: 30px;
  color: #e2d29d;
  margin-bottom: 22px; }

.programm-box .text .price i {
  font-family: 'als_rublregular';
  font-style: normal;
  font-size: 20px; }

.programm-box .text .more {
  position: relative;
  z-index: 9;
  display: block;
  opacity: 0;
  font-family: 'intro_regular';
  font-size: 20px;
  color: white;
  text-decoration: none;
  line-height: 50px;
  text-align: center;
  height: 0;
  width: 100%;
  border-radius: 3px;
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b);
  transition-duration: 0.3s; }

.programm-box .text .more:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s linear;
  border-radius: 3px;
  background: #ab9c5b; }

.programm-box .text .more:hover:before {
  opacity: 1; }

.programm-box:hover:after {
  background: rgba(0, 0, 0, 0.7); }

.programm-box:hover .opis {
  opacity: 1;
  height: auto;
  margin-bottom: 4px; }

.programm-box:hover .more {
  opacity: 1;
  height: 50px;
  margin-bottom: 30px; }

.programm-box.sale {
  background: #ba3c3d;
  height: 400px;
  padding: 0 30px; }

.programm-box.sale:after {
  display: none; }

.programm-box.sale .sale-ci {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 50px;
  line-height: 100px;
  text-align: center;
  background: white;
  width: 160px;
  height: 100px;
  border-radius: 3px;
  position: absolute;
  right: 10px;
  top: 10px;
  transition-duration: 0.3s; }

.programm-box.sale .sale-ci:after {
  content: '';
  position: absolute;
  border: 32px solid white;
  border-bottom: 0 solid #ba3c3d;
  border-left: 80px solid #ba3c3d;
  border-right: 80px solid #ba3c3d;
  left: 0;
  bottom: -30px; }

.programm-box.sale .text .tit {
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: white;
  margin-bottom: 36px;
  transition-duration: 0.6s; }

.programm-box.sale .text .opis {
  transition-duration: 0.6s; }

.programm-box.sale:hover .sale-ci {
  opacity: 0;
  height: 0; }

.programm-box.sale:hover .tit {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 17px; }

.programm-box.sale:hover .opis {
  margin-bottom: 30px; }

.programm-box.sale .more {
  display: none;
  visibility: hidden; }

.section-3 {
  position: relative; }

.section-3 .title {
  margin-bottom: 47px; }

.who-orders-our-shows {
  background: #efefef;
  padding: 63px 0 60px 0; }

.who-orders-our-shows-logos > div {
  text-align: center;
  display: block;
  margin-bottom: 46px;
  min-height: 55px; }

.who-orders-our-shows-logos > div img {
  opacity: 0.6;
  display: inline-block;
  max-width: 100%; }

.who-orders-our-shows-type {
  font-family: 'Roboto', sans-serif;
  font-weight: 400; }

.who-orders-our-shows-type .outher {
  height: 80px;
  font-size: 16px;
  line-height: 21px;
  text-align: center; }

.who-orders-our-shows-type .outher span {
  display: table-cell;
  vertical-align: middle;
  height: inherit;
  width: 500px;
  color: #5d5d5d; }

.who-orders-our-shows-type .who-1,
.who-orders-our-shows-type .who-2 {
  height: 80px;
  background: #f8f8f8;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 20px; }

.who-orders-our-shows-type .who-1 span,
.who-orders-our-shows-type .who-2 span {
  display: table-cell;
  vertical-align: middle;
  height: inherit;
  width: 500px;
  font-size: 16px;
  line-height: 21px;
  color: #5d5d5d; }

.who-orders-our-shows-type .who-1 {
  transform: skew(-3deg, -2deg); }

.who-orders-our-shows-type .who-1 span {
  transform: skew(3deg, 2deg); }

.who-orders-our-shows-type .who-2 {
  transform: skew(-3deg, 2deg); }

.who-orders-our-shows-type .who-2 span {
  transform: skew(3deg, -2deg); }

.section-4 {
  position: relative;
  padding: 52px 0 63px 0; }

.section-4 .title {
  margin-bottom: 69px; }

.section-4 .icon-down-cur {
  background: transparent; }

.section-4 .icon-down-cur:after {
  content: '';
  width: 60px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 30px 30px 0 0;
  background: #0071C9; }

#owl-carousel-1 {
  padding: 0 40px;
  box-sizing: border-box; }

#owl-carousel-1 .item-box {
  background: #f6f6f6;
  padding: 20px;
  margin-bottom: 10px;
  min-height: 280px;
  border-radius: 3px; }

#owl-carousel-1 .item-box .text {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #5d5d5d;
  text-align: center;
  border-right: 2px solid #e4e4e4;
  padding-right: 20px; }

#owl-carousel-1 .item-box .text > div {
  display: table-cell;
  vertical-align: middle;
  height: 240px;
  width: 1000px; }

#owl-carousel-1 .item-box .text iframe {
  width: 100%;
  height: 220px;
  max-height: 100%; }

#owl-carousel-1 .item-box .big-pic {
  display: block;
  transition-duration: 0.3s; }

#owl-carousel-1 .item-box .big-pic img {
  display: block;
  width: 100%;
  border-radius: 3px; }

#owl-carousel-1 .item-box .big-pic .icon-play {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -30px;
  font-size: 60px;
  color: white; }

#owl-carousel-1 .item-box .big-pic:hover {
  opacity: 0.9; }

#owl-carousel-1 .item-box .man-face-box {
  font-family: 'Roboto', sans-serif;
  text-align: center; }

#owl-carousel-1 .item-box .man-face-box span {
  display: table-cell;
  vertical-align: middle;
  height: 240px;
  width: 1000px; }

#owl-carousel-1 .item-box .man-face-box .pic {
  margin-bottom: 16px; }

#owl-carousel-1 .item-box .man-face-box .pic img {
  display: block;
  border-radius: 50%;
  width: auto;
  margin: 0 auto;
  border: 5px solid #dac98d; }

#owl-carousel-1 .item-box .man-face-box .name {
  font-weight: 300;
  font-size: 20px;
  color: #5d5d5d;
  margin-bottom: 5px; }

#owl-carousel-1 .item-box .man-face-box .status {
  font-weight: 400;
  font-size: 16px;
  color: #d1c391; }

#owl-carousel-1 .owl-prev,
#owl-carousel-1 .owl-next,
#owl-carousel-4 .owl-prev,
#owl-carousel-4 .owl-next {
  position: absolute;
  top: 50%;
  margin-top: -27px;
  font-size: 33px;
  color: #dac98d;
  transition-duration: 0.3s; }

#owl-carousel-1 .owl-prev:before,
#owl-carousel-1 .owl-next:before,
#owl-carousel-4 .owl-prev:before,
#owl-carousel-4 .owl-next:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e903"; }

#owl-carousel-1 .owl-prev:hover,
#owl-carousel-1 .owl-next:hover,
#owl-carousel-4 .owl-prev:hover,
#owl-carousel-4 .owl-next:hover {
  opacity: 0.8; }

#owl-carousel-1 .owl-prev,
#owl-carousel-4 .owl-prev {
  left: 0; }

#owl-carousel-1 .owl-prev:before,
#owl-carousel-4 .owl-prev:before {
  content: "\e903"; }

#owl-carousel-1 .owl-next,
#owl-carousel-4 .owl-next {
  right: 0; }

#owl-carousel-1 .owl-next:before,
#owl-carousel-4 .owl-next:before {
  content: "\e904"; }

#owl-carousel-4 .owl-prev,
#owl-carousel-4 .owl-next {
  top: 45%;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 13px 20px 10px 22px;
  right: -80px; }

#owl-carousel-4 .owl-prev {
  padding: 13px 20px 10px 18px;
  right: 0;
  left: -80px; }

.owl-thumb-item {
  width: 20%;
  margin: 0 10px; }

.owl-thumb-item img {
  width: 100%; }

.owl-dots {
  text-align: center;
  font-size: 0;
  margin-top: 30px; }

.owl-dots .owl-dot {
  width: 20px;
  height: 20px;
  margin: 0 4px;
  border-radius: 50%;
  border: 1px solid #d8c58a;
  display: inline-block;
  vertical-align: top; }

.owl-dots .owl-dot.active {
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b); }

.section-5 {
  min-width: 320px; }

.call-back-box {
  background: url("../images/bg-question.jpg") repeat-x scroll center top;
  height: 360px;
  padding: 68px 0 0 0;
  box-sizing: border-box; }

.call-back-box .title {
  padding-bottom: 27px; }

.call-back-box p {
  font-family: 'clear_sansregular';
  font-size: 20px;
  line-height: 24px;
  color: white;
  text-align: center;
  padding-bottom: 48px;
  max-width: 450px;
  margin: 0 auto; }

.call-back-box .call-back-bt {
  cursor: pointer;
  font-family: 'intro_regular';
  font-size: 20px;
  line-height: 70px;
  text-align: center;
  color: white;
  border-radius: 3px;
  margin: 0 auto;
  width: 350px;
  height: 70px;
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b);
  transition-duration: 0.3s; }

.call-back-box .call-back-bt:hover {
  opacity: 0.9; }

.section-6 {
  min-width: 320px;
  background: #eaeaea;
  padding: 62px 0 0 0; }

.section-6 .title {
  font-size: 26px;
  margin-bottom: 52px; }

.section-6 .title a {
  text-decoration: none;
  color: #d1c391; }

.section-6 .title a:hover {
  text-decoration: underline; }

.section-6 .title.icon-instagram:before {
  color: #77a2bb;
  font-size: 30px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top; }

/* Footer
-----------------------------------------------------------------------------*/
.footer {
  margin: -90px auto 0;
  height: 90px;
  position: relative;
  border-top: 1px solid #d6d6d6; }

.foot-left {
  float: left;
  padding-top: 26px; }

.foot-left .copy {
  font-family: 'intro_regular';
  float: left;
  font-size: 16px;
  line-height: 35px;
  color: #5d5d5d;
  border-right: 2px solid #e7e7e7;
  padding-right: 17px;
  margin-right: 24px; }

.foot-left .copy span {
  font-size: 12px; }

.foot-left .icon-copy:before {
  font-size: 24px;
  color: black;
  display: inline-block;
  vertical-align: middle;
  margin-right: 9px; }

.foot-left .email-us {
  font-family: 'clear_sansregular';
  font-size: 16px;
  float: left;
  border-right: 2px solid #e7e7e7;
  line-height: 35px;
  padding-right: 25px;
  margin-right: 22px; }

.foot-left .email-us a,
.foot-left .email-us span {
  cursor: pointer;
  color: #5d5d5d;
  text-transform: uppercase;
  text-decoration: none;
  transition-duration: 0.3s; }

.foot-left .email-us a:hover,
.foot-left .email-us span:hover {
  opacity: 0.9; }

.foot-left .social-foot {
  float: left; }

.foot-left .social-foot span {
  display: block;
  float: left;
  font-family: 'clear_sansregular';
  color: #5d5d5d;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 14px;
  line-height: 35px; }

.foot-left .social-foot .soc-ico {
  float: left;
  height: 31px;
  overflow: hidden; }

.foot-left .social-foot .soc-ico > a {
  font-size: 30px;
  color: #4b4b4b;
  margin-right: 9px;
  transition-duration: 0.3s;
  text-decoration: none; }

.foot-left .social-foot .soc-ico > a:hover {
  opacity: 0.9; }

.foot-right {
  float: right;
  padding: 20px 0 0 0; }

.foot-right .phone {
  font-family: 'intro_regular';
  float: right;
  font-size: 18px;
  float: left;
  line-height: 50px;
  margin-right: 17px; }

.foot-right .phone a {
  color: #5d5d5d;
  text-decoration: none; }

.foot-right .call-back-bt-foot {
  float: right;
  cursor: pointer;
  color: #5d5d5d;
  font-family: 'intro_regular';
  font-size: 14px;
  width: 204px;
  height: 50px;
  border: 1px solid #4b4b4b;
  border-radius: 3px;
  text-align: center;
  line-height: 50px;
  transition-duration: 0.3s; }

.foot-right .call-back-bt-foot:hover {
  opacity: 0.7; }

/* Внутряки
------------------------------------------------------*/
.section-1-inset-page {
  position: relative;
  min-height: 320px;
  min-width: 320px; }

.section-1-programm-cat {
  background: url("../images/bg-main-section-1.jpg") no-repeat scroll center top black;
  padding-top: 100px;
  min-width: 320px;
  box-sizing: border-box; }

.section-contacts {
  min-width: 320px;
  box-sizing: border-box;
  padding-top: 60px; }

.inset-top-info {
  color: white;
  display: table-cell;
  vertical-align: middle;
  padding: 11px 90px 20px 0;
  background: rgba(0, 0, 0, 0.6); }

.inset-top-info .title,
.inset-top-info h1 {
  color: white;
  font-family: 'intro_inlineregular';
  font-size: 45px;
  line-height: 45px;
  margin: 0 0 14px -2px;
  font-weight: normal; }

.inset-top-info .title a,
.inset-top-info h1 a {
  color: #d1c391;
  display: inline-block; }

.inset-top-info .title i,
.inset-top-info h1 i {
  font-style: normal;
  display: inline-block; }

.inset-top-info p {
  font-family: 'clear_sansregular';
  font-size: 20px;
  line-height: 25px; }

.inset-top-info a {
  color: #d1c391;
  text-transform: uppercase; }

.inset-top-info a:hover {
  text-decoration: none; }

.inset-top-info span {
  cursor: pointer;
  color: #d1c391;
  text-transform: uppercase;
  border-bottom: 1px dashed #d1c391; }

.inset-top-info span:hover {
  border-bottom: 0; }

.max-wi {
  max-width: 540px; }

.section-1-programm {
  background-repeat: no-repeat;
  background-position: center top;
  background-color: black;
  background-attachment: scroll;
  min-height: 599px;
  padding-top: 170px;
  min-width: 320px; }

.section-1-programm .inset-top-info {
  padding: 24px 90px 32px 0; }

.section-1-programm .inset-top-info .title,
.section-1-programm .inset-top-info h1 {
  font-size: 40px; }

.section-1-programm .top-programm-links {
  max-width: 640px;
  margin-top: 10px; }

.section-1-programm .top-programm-links .go-to {
  cursor: pointer;
  position: relative;
  z-index: 9;
  font-family: 'clear_sans_mediumregular';
  font-size: 20px;
  display: block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: white;
  text-shadow: 0 1px 1px #584F2F;
  margin: 0 10px 10px 0;
  border-radius: 3px;
  text-decoration: none;
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b); }

.section-1-programm .top-programm-links .go-to.diamand:after {
  content: '';
  background: url("../images/diamand.png") no-repeat scroll center center white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  left: 20px;
  top: 12px; }

.section-1-programm .top-programm-links .go-to:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s linear;
  border-radius: 3px;
  background: #ab9c5b; }

.section-1-programm .top-programm-links .go-to:hover:before {
  opacity: 1; }

.section-2-prog-opis {
  position: relative;
  padding: 51px 0 70px 0; }

.section-2-prog-opis .title,
.section-2-prog-opis h2 {
  font-family: 'intro_regular';
  font-size: 30px;
  line-height: normal;
  color: #d1c391;
  padding: 0 0 23px 25px; }

.section-2-prog-opis .short-description {
  margin-bottom: 40px; }

.section-2-prog-opis .programm-opis-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #5d5d5d;
  padding: 0 90px 0 25px; }

.section-2-prog-opis .programm-opis-text p {
  padding-bottom: 20px; }

.section-2-prog-opis .programm-trailer .pic {
  margin-top: 8px;
  position: relative; }

.section-2-prog-opis .programm-trailer .pic img {
  display: block;
  width: 100%;
  border-radius: 3px; }

.section-2-prog-opis .programm-trailer .pic:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.5));
  border-radius: 3px; }

.play-trailer {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -25px 0 0 -105px;
  z-index: 2;
  padding-right: 19px;
  border-radius: 3px;
  transition-duration: 0.3s; }

.play-trailer .icon-play3 {
  padding: 10px 0 10px 10px;
  display: block;
  font-family: 'clear_sansregular';
  color: white;
  font-size: 17px; }

.play-trailer .icon-play3:before {
  background: #bb3d3d;
  width: 30px;
  height: 30px;
  font-size: 16px;
  color: white;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 11px;
  border-radius: 3px; }

.play-trailer:hover {
  background: rgba(0, 0, 0, 0.9); }

#owl-carousel-2,
#owl-carousel-3 {
  padding: 0 40px; }

#owl-carousel-2 img,
#owl-carousel-3 img {
  border-radius: 3px; }

#owl-carousel-2 .owl-prev,
#owl-carousel-3 .owl-prev,
#owl-carousel-2 .owl-next,
#owl-carousel-3 .owl-next {
  position: absolute;
  top: 50%;
  margin-top: -40px;
  font-size: 33px;
  color: #4b4b4b;
  transition-duration: 0.3s; }

#owl-carousel-2 .owl-prev:before,
#owl-carousel-3 .owl-prev:before,
#owl-carousel-2 .owl-next:before,
#owl-carousel-3 .owl-next:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e903"; }

#owl-carousel-2 .owl-prev:hover,
#owl-carousel-3 .owl-prev:hover,
#owl-carousel-2 .owl-next:hover,
#owl-carousel-3 .owl-next:hover {
  opacity: 0.8; }

#owl-carousel-2 .owl-prev,
#owl-carousel-3 .owl-prev {
  left: 0; }

#owl-carousel-2 .owl-prev:before,
#owl-carousel-3 .owl-prev:before {
  content: "\e903"; }

#owl-carousel-2 .owl-next,
#owl-carousel-3 .owl-next {
  right: 0; }

#owl-carousel-2 .owl-next:before,
#owl-carousel-3 .owl-next:before {
  content: "\e904"; }

/* Pricelist styles */
.section-3-price-show-box {
  background: #efefef;
  padding: 60px 0; }

.section-3-price-show-box .title,
.section-3-price-show-box h2 {
  font-family: 'intro_regular';
  font-size: 30px;
  line-height: normal;
  color: #d1c391;
  padding-bottom: 40px;
  text-align: center; }

.gw-go * {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #000; }

.gw-go .gw-go-col .gw-go-col-inner {
  border: solid 1px #ccc; }

.gw-go .gw-go-header {
  font-family: 'intro_regular';
  font-size: 20px;
  padding: 26px 0;
  border-right: 1px solid #e2d3a3;
  border-top: 1px solid #ded095;
  vertical-align: middle;
  text-decoration: none;
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b);
  box-sizing: border-box;
  height: 18px; }

.gw-go .gw-go-col-wrap-0 .gw-go-header {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  background: #efefef;
  border-bottom: 1px dashed #cbcbcb;
  border-top: none; }

.gw-go .gw-go-hover .gw-go-header {
  color: white;
  background: #ba3c3d;
  /*border-right: 1px solid #ba3c3d;*/
  border-top: 1px solid #ba3c3d; }

.gw-go .gw-go-col .gw-go-body li,
.gw-go .gw-go-col .gw-go-body li.gw-go-even {
  border-bottom: 1px dashed #cbcbcb;
  border-top: none;
  background: none;
  padding: 15px; }

.gw-go .gw-go-col .gw-go-body li:last-child {
  border-bottom: none; }

.gw-go .gw-go-col-wrap-0 .gw-go-col-inner,
.gw-go .gw-go-hover .gw-go-col-inner {
  background: #efefef !important; }

.gw-go .gw-go-col-wrap .gw-go-col .gw-go-body {
  border-bottom: none; }

.gw-go .gw-go-col-wrap-0 .gw-go-body li .gw-go-body-cell,
.gw-go .gw-go-col-wrap-0 .gw-go-body li.gw-go-even .gw-go-body-cell {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  color: #5d5d5d; }

.gw-go .minus {
  font-size: 40px;
  color: #c5534d; }

.gw-go .plus {
  font-size: 30px;
  color: #76933c; }

.section-3-price-show-box table thead tr th:nth-child(3) {
  border-right: 1px solid #ba3c3d; }

.section-3-price-show-box table tbody tr td:nth-child(4) {
  background: #f3f3f3; }

.section-3-price-show-box table tbody tr:last-child td {
  border-bottom: 1px solid #cccccc; }

.section-3-price-show-box table tbody tr:last-child td:nth-child(4) {
  border-bottom: 0; }

.section-3-price-show-box table tbody tr:nth-last-child(2) td {
  border-bottom: 0;
  padding-top: 30px; }

.section-3-price-show-box table tfoot tr td.last {
  height: 70px;
  box-shadow: 0px 10px 10px 5px gainsboro;
  background: #f3f3f3;
  padding: 0 15px 15px;
  border: 1px solid #cccccc;
  border-top: 0;
  border-radius: 0 0 3px 3px; }

.section-3-price-show-box .price {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px; }

.section-3-price-show-box .price.old {
  text-decoration: line-through !important; }

.section-3-price-show-box .price.new {
  color: #bc2527;
  font-size: 40px; }

.gw-go .price i {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px; }

.section-3-price-show-box .price.new i {
  color: #bc2527; }

.gw-go .gw-go-col-wrap-0 .gw-go-footer-wrap {
  background: #efefef; }

.gw-go .gw-go-btn.gw-go-btn-medium {
  cursor: pointer;
  font-family: 'intro_regular';
  font-size: 20px;
  height: 60px;
  color: white;
  line-height: 60px;
  border-radius: 3px;
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b); }

.gw-go .gw-go-col-wrap.gw-go-hover .gw-go-btn.gw-go-btn-medium {
  background: #d86565 !important;
  background: linear-gradient(#d86565, #bb3f3e) !important; }

/* end of price list */
.additional-services-box {
  text-align: center;
  padding: 55px 0 90px 0; }

.additional-services-box .title {
  padding-bottom: 17px; }

.additional-services-box p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #5d5d5d;
  max-width: 550px;
  margin: 0 auto;
  padding-bottom: 37px; }

.additional-services-box .select-button {
  cursor: pointer;
  font-family: 'intro_regular';
  font-size: 16px;
  line-height: 52px;
  color: #5d5d5d;
  width: 242px;
  height: 52px;
  border: 2px solid #c7c7c7;
  margin: 0 auto;
  border-radius: 3px;
  transition-duration: 0.3s; }

.additional-services-box .select-button:hover {
  opacity: 0.7; }

.section-1-uslugi-page {
  background: repeat-y scroll center top black;
  padding-top: 110px; }

.section-1-uslugi-page .inset-top-info {
  background: none;
  padding-left: 0 !important;
  padding-right: 0; }

.section-1-uslugi-page .services-box {
  bottom: 10px; }

.section-1-uslugi-page .services-box li a {
  height: 50px;
  line-height: 50px; }

.section-1-uslugi-page .services-box li.active a {
  background: transparent;
  border: 1px solid white;
  height: 50px;
  line-height: 48px; }

.section-1-uslugi-page .services-box li.active a:hover:before {
  opacity: 0; }

.section-1-uslugi-page .services-box li a i {
  font-size: 14px;
  font-style: normal; }

.uslugi-menu {
  margin-bottom: 59px; }

.nav-tabs {
  border-bottom: none; }

.nav-tabs li {
  min-width: 100px; }

.section-tabs > li {
  padding: 0 10px;
  box-sizing: border-box; }

.head-tabs > li {
  padding: 0 5px;
  box-sizing: border-box; }

.section-tabs > li:first-child,
.head-tabs > li:first-child {
  padding-left: 0; }

.section-tabs > li:last-child,
.head-tabs > li:last-child {
  padding-right: 0; }

.uslugi-menu .tab-bt-usl {
  cursor: pointer;
  font-family: 'clear_sans_mediumregular';
  font-size: 20px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-bottom: 2px solid #dac98d; }

.uslugi-menu .tab-bt-usl.active {
  border: 1px solid #959595;
  border-radius: 3px; }

.usluga-price-box {
  max-width: 860px;
  min-width: 320px;
  padding: 43px 10px 0;
  margin: 0 auto; }

.usluga-price-box .title {
  font-family: 'intro_regular';
  font-size: 26px;
  color: #5d5d5d;
  padding: 15px 20px 36px;
  line-height: normal; }

.usluga-price-box .title h1,
.usluga-price-box .title h2 {
  font-size: 26px;
  display: inline;
  color: #5d5d5d;
  font-weight: normal;
  margin: 0; }

.breadcrumbs {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 31px;
  padding: 0 20px; }

.breadcrumbs .alignright {
  text-align: right; }

.breadcrumbs .back {
  text-decoration: none;
  color: black; }

.breadcrumbs .back:before {
  font-size: 22px;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px; }

.breadcrumbs .back span {
  text-decoration: underline; }

.breadcrumbs .back span i {
  font-style: normal; }

.breadcrumbs .back:hover span {
  text-decoration: none; }

.breadcrumbs .next {
  text-decoration: none;
  color: black; }

.breadcrumbs .next:after {
  font-size: 22px;
  display: inline-block;
  vertical-align: top;
  margin-left: 8px; }

.breadcrumbs .next span {
  text-decoration: underline; }

.breadcrumbs .next span i {
  font-style: normal; }

.breadcrumbs .next:hover span {
  text-decoration: none; }

.breadcrumbs .amp {
  padding: 0 6px; }

.usluga-price-box-info {
  padding-bottom: 40px; }

.usluga-price-box-info .usluga-trailer img {
  width: 100%;
  display: block;
  border-radius: 3px; }

.usluga-price-box-info .top-meta {
  text-align: center;
  background: #efefef;
  border-radius: 3px; }

.usluga-price-box-info .top-meta .inset {
  display: table-cell;
  vertical-align: middle;
  width: 1000px;
  height: inherit; }

.usluga-price-box-info .top-meta .summa {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  color: #5d5d5d;
  margin-bottom: 15px;
  padding-right: 27px; }

.usluga-price-box-info .top-meta .summa i {
  font-family: 'als_rublregular';
  font-style: normal;
  font-size: 30px; }

.usluga-price-box-info .top-meta .book-now-bt {
  margin: 0 auto;
  cursor: pointer;
  font-family: 'intro_regular';
  font-size: 20px;
  line-height: 48px;
  text-align: center;
  color: #5d5d5d;
  width: 252px;
  height: 52px;
  border: 2px solid #5d5d5d;
  border-radius: 3px;
  transition-duration: 0.3s;
  margin-bottom: 36px; }

.usluga-price-box-info .top-meta .book-now-bt:hover {
  opacity: 0.7; }

.usluga-price-box-info .top-meta p {
  font-family: 'clear_sansregular';
  font-size: 14px;
  line-height: 18px;
  padding: 0 26px; }

.table-price-box {
  border-bottom: 1px solid #909090; }

.table-price-box table {
  width: 100%; }

.table-price-box table thead {
  font-family: 'intro_regular';
  height: 60px;
  text-align: left; }

.table-price-box table thead tr th {
  padding: 10px 20px;
  background: #dbc379;
  border-right: 2px solid rgba(150, 150, 150, 0.1); }

.table-price-box table thead tr th.tdpic {
  padding: 10px;
  border-right: 0;
  border-radius: 0 3px 3px 0; }

.table-price-box table thead tr th:first-child {
  border-radius: 3px 0 0 3px; }

.table-price-box table thead tr th i {
  font-family: 'als_rublregular';
  font-style: normal;
  font-size: 12px; }

.table-price-box table tbody {
  font-family: 'clear_sansregular';
  font-size: 16px; }

.table-price-box table tbody tr {
  height: 80px; }

.table-price-box table tbody tr:nth-child(2n+2) td {
  background: #efefef; }

.table-price-box table tbody tr.empty {
  height: 20px; }

.table-price-box table tbody tr td {
  padding: 10px 20px;
  border-right: 2px solid rgba(150, 150, 150, 0.1); }

.table-price-box table tbody tr td.tdpic {
  padding: 10px;
  border-right: 0;
  border-radius: 0 3px 3px 0; }

.table-price-box table tbody tr td:first-child {
  border-radius: 3px 0 0 3px; }

.table-price-box table tbody tr td .pic {
  cursor: pointer;
  position: relative;
  display: block; }

.table-price-box table tbody tr td .pic img {
  display: block;
  width: 100%; }

.table-price-box table tbody tr td .pic b {
  position: absolute;
  right: 8px;
  bottom: 8px;
  color: white;
  font-size: 16px; }

.table-price-box .bottom-meta {
  padding: 39px 0 31px 0;
  text-align: center; }

.table-price-box .bottom-meta .summa {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  color: #5d5d5d;
  text-align: right;
  padding-right: 27px; }

.table-price-box .bottom-meta .summa i {
  font-family: 'als_rublregular';
  font-style: normal;
  font-size: 30px; }

.table-price-box .bottom-meta .book-now-bt {
  cursor: pointer;
  font-family: 'intro_regular';
  font-size: 20px;
  line-height: 48px;
  text-align: center;
  color: #5d5d5d;
  width: 252px;
  height: 52px;
  border: 2px solid #5d5d5d;
  border-radius: 3px;
  transition-duration: 0.3s; }

.table-price-box .bottom-meta .book-now-bt:hover {
  opacity: 0.7; }

.section-1-about {
  background: url("../images/about_bg.jpg") no-repeat scroll center top black;
  padding-top: 100px; }

.briefly-about-project .title,
.legal-information .title,
.thanks-slider-box .title,
.briefly-about-project h1,
.legal-information h1,
.thanks-slider-box h1,
.briefly-about-project h2,
.legal-information h2,
.thanks-slider-box h2,
.briefly-about-project h3,
.legal-information h3,
.thanks-slider-box h3 {
  font-family: 'intro_regular';
  font-size: 30px;
  line-height: 30px;
  color: #d1c391;
  margin: 0;
  font-weight: normal; }

.briefly-about-project {
  padding: 59px 0 60px 0; }

.briefly-about-project .title,
.briefly-about-project h2,
.briefly-about-project h3,
.briefly-about-project h1 {
  padding-bottom: 25px; }

.briefly-about-project .kolonki {
  column-count: 2;
  column-gap: 80px;
  padding-bottom: 39px; }

.briefly-about-project p {
  font-family: 'clear_sansregular';
  font-size: 16px;
  line-height: 21px; }

.briefly-about-project .show-more {
  margin: 0 auto;
  cursor: pointer;
  color: #5d5d5d;
  font-family: 'intro_regular';
  font-size: 16px;
  width: 300px;
  height: 60px;
  border: 1px solid #4b4b4b;
  border-radius: 3px;
  text-align: center;
  line-height: 60px;
  transition-duration: 0.3s; }

.briefly-about-project .show-more:hover {
  opacity: 0.7; }

.legal-information {
  background: #efefef;
  padding: 40px 0 51px 0; }

.legal-information .title,
.legal-information h1,
.legal-information h2,
.legal-information h3 {
  padding-top: 17px;
  padding-bottom: 22px; }

.legal-information .name {
  font-family: 'intro_regular';
  font-size: 18px;
  line-height: 22px;
  color: #d1c391;
  height: 44px;
  display: table-cell;
  vertical-align: bottom;
  padding-left: 10px; }

.legal-information p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #5d5d5d;
  padding-left: 17px; }

.legal-information .about_gallery {
  padding: 0 5px;
  box-sizing: border-box; }

.legal-information .pic {
  position: relative;
  margin-top: 19px;
  max-height: 150px;
  overflow: hidden; }

.legal-information .pic img {
  display: block;
  width: 100%; }

.legal-information .pic .icon-loopa {
  font-size: 25px;
  color: white;
  position: absolute;
  right: 10px;
  bottom: 10px; }

.thanks-slider-box {
  padding: 56px 0 59px 0; }

.thanks-slider-box .title,
.thanks-slider-box h1,
.thanks-slider-box h2,
.thanks-slider-box h3 {
  padding-bottom: 35px; }

.more-about-black-box {
  background: url("../images/about_black.jpg") repeat-y fixed center top black; }

.more-about-head {
  background: rgba(0, 0, 0, 0.3);
  padding: 50px 0 40px 0; }

.more-about-head .title {
  font-size: 30px;
  font-family: 'intro_regular';
  color: #d1c391;
  line-height: 30px;
  margin-top: 9px;
  padding-bottom: 20px; }

.more-about-head .about-menu li {
  text-align: center;
  list-style: none; }

.more-about-head .about-menu li a {
  height: 48px;
  font-family: 'clear_sansregular';
  font-size: 16px;
  line-height: 18px;
  display: block;
  color: white;
  border-bottom: 1px solid white;
  text-decoration: none; }

.more-about-head .about-menu li a span {
  height: 46px;
  width: 500px;
  display: table-cell;
  vertical-align: middle; }

.more-about-head .about-menu li a.active {
  height: 50px;
  color: black;
  border-radius: 3px;
  background: #ded095;
  background: linear-gradient(#ded095, #d7bf7b);
  border: 0; }

.more-about-head .about-menu li a.active span {
  height: 48px; }

.more-about-body {
  color: white; }

.more-about-body .title,
.more-about-body h1,
.more-about-body h2,
.more-about-body h3,
.more-about-body h4 {
  font-family: 'intro_regular';
  font-size: 40px;
  line-height: 40px;
  color: white;
  padding-bottom: 38px;
  margin: 0; }

.more-about-body .left-dark-col {
  padding-top: 55px;
  padding-right: 50px;
  padding-bottom: 117px;
  background: white;
  background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=1); }

.more-about-body .left-dark-col p {
  font-family: 'clear_sansregular';
  font-size: 16px;
  line-height: 21px;
  padding-bottom: 11px; }

.more-about-body .left-dark-col ul {
  font-family: 'clear_sansregular';
  font-size: 16px;
  line-height: 21px;
  padding-bottom: 15px; }

.more-about-body .left-dark-col a {
  color: #74a2e5; }

.more-about-body .left-dark-col a:hover {
  text-decoration: none; }

.more-about-body .right-dark-col {
  padding-top: 133px;
  padding-left: 55px; }

.more-about-body .right-dark-col .mini-box {
  max-width: 310px;
  margin-bottom: 24px; }

.more-about-body .right-dark-col .mini-box .pic {
  margin-bottom: 12px; }

.more-about-body .right-dark-col .mini-box .pic img {
  display: block;
  width: 100%; }

.more-about-body .right-dark-col .mini-box p {
  font-family: 'clear_sansregular';
  font-size: 16px;
  line-height: 21px;
  padding-left: 20px; }

.more-about-body .post {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 18px;
  margin-bottom: 20px;
  color: white; }

.more-about-body .post img {
  display: block;
  width: 100%; }

.more-about-body .post .post-title,
.more-about-body .post h1,
.more-about-body .post h2,
.more-about-body .post h3 {
  padding-bottom: 10px;
  font-family: 'intro_regular';
  margin: 0;
  font-weight: normal; }

.more-about-body .post .post-title a,
.more-about-body .post h1 a,
.more-about-body .post h2 a,
.more-about-body .post h3 a {
  font-size: 20px;
  line-height: 20px;
  color: white;
  text-decoration: none; }

.more-about-body .post p {
  font-size: 18px; }

.more-about-body .post.one {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0; }

.more-about-body .post.one .post-title,
.more-about-body .post.one h1,
.more-about-body .post.one h2,
.more-about-body .post.one h3 {
  font-size: 30px;
  line-height: 30px;
  color: #d1c391;
  padding-bottom: 25px;
  margin: 0;
  font-weight: normal; }

.more-about-body .post.one img {
  margin: 20px 0; }

.more-about-body .right-dark-col-2 {
  padding-top: 55px;
  padding-left: 50px; }

.more-about-body .right-dark-col-2 .title {
  font-size: 26px;
  padding-bottom: 30px; }

.more-about-body .right-dark-col-2 .truppa-video {
  position: relative; }

.more-about-body .right-dark-col-2 .truppa-video .pic {
  padding-bottom: 10px; }

.more-about-body .right-dark-col-2 .truppa-video img {
  width: 100%; }

.more-about-body .right-dark-col-2 p {
  font-family: 'clear_sansregular';
  font-size: 18px;
  line-height: 22px;
  padding-left: 10px; }

.more-about-body .breadcrumbs {
  padding: 0; }

.more-about-body .breadcrumbs a {
  color: white; }

.section-1-news-page {
  background: url("../images/news_bg.jpg") no-repeat scroll center top black;
  padding-top: 100px; }

.news-page-list {
  margin-top: 55px; }

.news-page-list .post {
  border-bottom: 1px solid #959595;
  padding-bottom: 0;
  margin-bottom: 35px; }

.news-page-list .post.one {
  border-bottom: 0; }

.news-page-list .post .post-title {
  font-family: 'intro_regular';
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 22px;
  display: inline-block; }

.news-page-list .post .post-title a {
  color: #d1c391;
  text-decoration: none; }

.news-page-list .post .pic {
  position: relative;
  margin-bottom: 23px; }

.news-page-list .post .pic img {
  width: 100%;
  display: block; }

.news-page-list .post .pic .date {
  font-family: 'intro_regular';
  font-size: 16px;
  color: white;
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  background: black;
  border-radius: 3px; }

.news-page-list .post p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #5d5d5d;
  padding-bottom: 19px; }

.news-page-list h1,
.news-page-list h2,
.news-page-list .title {
  font-family: 'intro_regular';
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 22px;
  margin-top: 15px;
  display: inline-block;
  color: #d1c391;
  font-weight: normal; }

.paginator {
  font-size: 0;
  text-align: center;
  margin: 0 0 49px 0;
  padding-top: 6px; }

.paginator a {
  font-family: 'clear_sansregular';
  color: #d1c391 !important;
  font-size: 24px;
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  border-radius: 50%;
  transition-duration: 0.3s; }

.paginator a:hover {
  opacity: 0.9; }

.paginator a.active {
  color: white !important;
  background: #d1c391; }

.contact-left-col {
  background: url("../images/contact-bg.jpg") no-repeat fixed left top;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  padding-right: 140px;
  min-width: 320px; }

.contact-left-col h1,
.contact-left-col h2,
.contact-left-col h3,
.contact-left-col .title {
  margin: 0;
  font-family: 'intro_inlineregular';
  font-size: 44px;
  line-height: 44px;
  color: white;
  padding-bottom: 22px;
  font-weight: normal; }

.contact-left-col p {
  font-family: 'clear_sansregular';
  font-size: 20px;
  line-height: 25px;
  color: white;
  padding-bottom: 64px; }

.contact-left-col .contact-box-bt {
  font-family: 'intro_regular';
  width: 332px; }

.contact-left-col .ili {
  font-size: 15px;
  color: white;
  text-align: center;
  padding: 25px 0; }

.contact-left-col .contact-bt {
  cursor: pointer;
  font-size: 20px;
  color: white;
  text-align: center;
  height: 62px;
  width: 100%;
  line-height: 58px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  transition-duration: 0.3s; }

.contact-left-col .contact-bt:hover {
  opacity: 0.7; }

.col-vh-50 {
  min-height: 50vh; }

.col-vh-50:nth-child(2n) {
  background: #eaeaea; }

.employee-box {
  color: #5d5d5d; }

.employee-box .pic img {
  border-radius: 50%;
  margin: 0 auto;
  display: block; }

.employee-box .name {
  font-family: 'intro_regular';
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 4px; }

.employee-box .status {
  font-family: 'clear_sansregular';
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 21px; }

.employee-box .back-info {
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300; }

.employee-box .back-info a {
  color: #76a3e4; }

.employee-box .back-info a:hover {
  text-decoration: none; }

.employee-box .back-info .phone-line {
  margin-bottom: 6px; }

.employee-box .back-info .phone-line a {
  color: #5d5d5d;
  text-decoration: none; }

/*  POPUP
----------------------------------------------------------------------------*/
.default-popup {
  position: relative;
  width: 530px;
  padding: 30px;
  margin: 0 auto;
  z-index: 1000;
  background: white;
  border-radius: 3px; }

.default-popup .close {
  cursor: pointer;
  font-size: 20px;
  color: white;
  position: absolute;
  right: -26px;
  top: -26px;
  transition-duration: 0.3s; }

.default-popup .close:hover {
  opacity: 0.7; }

.default-popup .title {
  font-family: 'intro_regular';
  font-size: 26px;
  line-height: 26px;
  color: #5d5d5d;
  text-align: center;
  margin-bottom: 21px; }

.default-popup .min-info {
  font-family: 'clear_sansregular';
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 35px;
  padding: 0 30px; }

.default-popup .min-info span {
  color: #bc2527; }

.default-popup .action-box {
  background: #dac98d;
  padding: 30px 30px 20px;
  border-radius: 3px; }

.default-popup .action-box .ln {
  margin-bottom: 10px; }

.default-popup .action-box input[type=text],
.default-popup .action-box input[type=tel],
.default-popup .action-box input[type=email] {
  font-family: 'clear_sansregular';
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  border: 0;
  background: white;
  border-radius: 3px; }

.default-popup .action-box textarea {
  font-family: 'clear_sansregular';
  font-size: 16px;
  width: 100%;
  padding: 15px 20px;
  height: 170px;
  line-height: normal;
  border: 0;
  background: white;
  border-radius: 3px; }

.default-popup .action-box input[type=submit] {
  font-family: 'intro_regular';
  font-size: 20px;
  color: white;
  background: transparent;
  border: 2px solid white;
  width: 100%;
  height: 60px;
  margin-top: 10px;
  border-radius: 3px;
  transition-duration: 0.3s; }

.default-popup .action-box input[type=submit]:hover {
  opacity: 0.7; }

.default-popup .examples-of-topics {
  background: #dac98d;
  margin-top: 10px;
  padding: 32px 30px;
  text-align: center;
  border-radius: 3px; }

.default-popup .examples-of-topics .tit {
  font-family: 'clear_sansregular';
  color: white;
  font-size: 24px;
  text-align: center;
  padding-bottom: 22px; }

.default-popup .examples-of-topics p {
  font-family: 'clear_sansregular';
  color: #5d5d5d;
  font-size: 18px;
  line-height: 24px; }

.thanks-popup .min-info {
  font-family: 'clear_sansregular';
  font-size: 18px;
  line-height: 24px;
  padding: 0 50px; }

.thanks-popup .action-box {
  background: #dac98d;
  padding: 13px 30px 20px;
  border-radius: 3px; }

.thanks-popup .action-box .min-info {
  margin-bottom: 9px;
  padding: 0 30px; }

.order-your-show-popup .min-info {
  margin-bottom: 17px; }

/*---------------------------------------*/
.addons-popup {
  position: relative;
  width: 740px;
  padding: 30px;
  margin: 0 auto;
  z-index: 1000;
  background: white;
  border-radius: 3px; }

.addons-popup .close {
  cursor: pointer;
  font-size: 20px;
  color: white;
  position: absolute;
  right: -26px;
  top: -26px;
  transition-duration: 0.3s; }

.addons-popup .close:hover {
  opacity: 0.7; }

.addons-popup .title {
  font-family: 'intro_regular';
  font-size: 26px;
  line-height: 26px;
  color: #5d5d5d;
  margin-bottom: 16px;
  padding-left: 10px; }

.addons-popup .min-info {
  font-family: 'clear_sansregular';
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 26px;
  padding-left: 10px; }

.addons-popup .table-price-box input[type=text] {
  width: 100%;
  height: 40px;
  background: white;
  border: 1px solid #959595;
  border-radius: 3px;
  padding: 0 20px; }

.addons-popup .table-price-box .min {
  font-size: 10px;
  height: 40px; }

.addons-popup .etc-qwestion {
  font-family: 'clear_sansregular';
  font-size: 16px;
  text-align: center;
  margin-top: 22px; }

.addons-popup .etc-qwestion span {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold; }

.addons-popup .etc-qwestion span:hover {
  text-decoration: none; }

.trailer-popup {
  display: none;
  position: relative;
  width: 767px;
  height: 528px;
  margin: 0 auto;
  z-index: 1000; }

.trailer-popup .close2 {
  cursor: pointer;
  font-size: 20px;
  color: white;
  position: absolute;
  right: -26px;
  top: -26px;
  transition-duration: 0.3s; }

.trailer-popup .close2:hover {
  opacity: 0.7; }

.trailer-popup #popup_video_block {
  margin-bottom: 45px; }

.trailer-popup .share-box {
  text-align: center;
  font-size: 0; }

.trailer-popup .share-box > span {
  line-height: 50px;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  color: white;
  margin-right: 10px; }

.trailer-popup .share-box .icon-font {
  cursor: pointer;
  display: inline-block;
  font-size: 50px;
  text-decoration: none;
  vertical-align: top;
  margin: 0 5px;
  background: white;
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 6px; }

.trailer-popup .share-box .icon-font.icon-facebook2:before {
  color: #425282;
  position: absolute;
  left: -1px;
  top: -1px; }

.trailer-popup .share-box .icon-font.icon-vk {
  color: #556b8e; }

.trailer-popup .share-box .icon-font.icon-vk:before {
  color: #556b8e;
  position: absolute;
  left: -1px;
  top: -1px; }

.trailer-popup .share-box .icon-font.icon-tw {
  color: #1da1f2; }

.trailer-popup .share-box .icon-font.icon-tw:before {
  color: #1da1f2;
  position: absolute;
  left: -1px;
  top: -1px; }

.lightbox {
  cursor: pointer; }

.lightbox-popup {
  display: none;
  position: relative;
  width: 767px;
  height: 528px;
  margin: 0 auto;
  z-index: 1000; }

.lightbox-popup .close2 {
  cursor: pointer;
  font-size: 20px;
  color: white;
  position: absolute;
  right: -26px;
  top: -26px;
  transition-duration: 0.3s; }

.lightbox-popup .close2:hover {
  opacity: 0.7; }

.lightbox-popup #popup_gallery_block {
  margin-bottom: 45px; }

.lightbox-popup #popup_gallery_block img {
  max-width: 100%; }

#mask {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(18, 24, 38, 0.8);
  z-index: 999; }

#container_all {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1; }

#layer-for-all-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 50px 0 10px 0;
  display: none; }

/* group Base */
@media (max-width: 1100px) {
  .header .phone {
    margin-right: 26px; }
  .header .menu {
    padding-left: 18px; }
  .foot-left .social-foot span {
    display: none; }
  .section-3-price-show-box .bron-bt {
    font-size: 16px; } }

@media (max-width: 992px) {
  .header .menu ul li a {
    font-size: 14px;
    padding: 0 5px; }
  .header .phone a {
    font-size: 16px; }
  .header .lang-select .lang-tab {
    display: none; }
  .header .lang-select .ru-link {
    margin-right: 7px; }
  .programs-menu .kvo {
    position: relative;
    display: inline-block;
    padding-left: 10px;
    margin-left: 10px;
    font-size: 12px;
    color: #5d5d5d; }
  .programs-menu .kvo:before {
    content: '/';
    position: absolute;
    left: 0;
    top: 0; }
  .programs-menu .tab-bt.active .kvo {
    color: white; }
  .who-orders-our-shows-logos > div {
    margin-bottom: 26px; }
  #owl-carousel-1 .item .item-box:last-child {
    display: none; }
  .foot-left .email-us {
    display: none; }
  .section-2-prog-opis .programm-opis-text {
    padding: 0 20px; }
  .more-about-body .left-dark-col {
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 20px; }
  .more-about-body .right-dark-col {
    padding-top: 10px;
    padding-left: 0;
    margin: 0 -5px; }
  .more-about-body .right-dark-col .mini-box {
    max-width: 500px;
    padding: 0 5px; }
  .more-about-body .right-dark-col-2 {
    padding-left: 0;
    padding-top: 20px;
    padding-bottom: 20px; }
  .employee-box {
    text-align: center; }
  .employee-box .pic {
    margin-bottom: 10px; } }

@media (max-width: 800px) {
  .addons-popup {
    width: 100%;
    min-width: 320px;
    left: 0;
    margin-left: 0; }
  .addons-popup .close {
    right: 6px;
    top: 6px;
    color: black; }
  .section-3-price-show-box .bron-bt {
    font-size: 14px; }
  .trailer-popup {
    width: 100%;
    left: 0;
    top: 30px;
    margin-left: 0;
    margin-top: 0;
    min-width: 320px; }
  .trailer-popup .close2 {
    right: 6px; }
  .lightbox-popup {
    width: 100%;
    left: 0;
    top: 30px;
    margin-left: 0;
    margin-top: 0;
    min-width: 320px; }
  .lightbox-popup .close2 {
    right: 6px; } }

@media (max-width: 767px) {
  .header .menu .mobile-menu-bt {
    display: block; }
  .header .menu-list {
    display: none;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background: black;
    z-index: 101;
    min-width: 320px; }
  .header .menu ul li {
    display: block; }
  .header .menu ul li a {
    padding: 0 30px; }
  .section-1 {
    min-height: 0; }
  .services-box {
    position: inherit;
    padding-bottom: 40px; }
  .services-box .wrap {
    padding: 0;
    min-width: 300px; }
  .programs-menu .kvo {
    display: none; }
  .foot-right .call-back-bt-foot {
    display: none; }
  .foot-right .phone {
    margin-right: 0; }
  .table-price-box table tbody tr td.tdpic,
  .table-price-box table thead tr th.tdpic {
    display: none; }
  .table-price-box table thead tr th {
    padding: 0 10px; }
  .table-price-box table tbody tr td {
    padding: 10px; }
  .usluga-price-box-info .top-meta .inset {
    padding: 15px 0; }
  .briefly-about-project .kolonki {
    column-count: 1;
    column-gap: 0;
    padding-bottom: 19px; }
  .briefly-about-project .kolonki p {
    padding-bottom: 15px; }
  .contact-left-col {
    min-height: inherit;
    padding-right: 0;
    text-align: center;
    padding: 25px 0; }
  .contact-left-col .contact-box-bt {
    margin: 0 auto; }
  .col-vh-50 {
    min-height: inherit; }
  .employee-box {
    padding: 20px 0; }
  .contact-left-col p {
    padding: 0 20px 34px; }
  .more-about-head .about-menu li {
    margin-bottom: 20px; }
  .more-about-head .about-menu li a {
    border: 1px solid white;
    border-radius: 3px; }
  #owl-carousel-1 {
    padding: 0; }
  .owl-nav {
    display: none; } }

@media (max-width: 650px) {
  .inset-top-info {
    padding: 11px 20px 20px 0; }
  .section-1-programm .inset-top-info {
    padding: 24px 20px 32px 0; }
  .max-wi {
    max-width: inherit; } }

@media (max-width: 600px) {
  .foot-left .social-foot {
    display: none; }
  .foot-left .copy {
    border-right: 0;
    margin-right: 0;
    padding-right: 0; }
  .default-popup {
    width: 100%;
    min-width: 320px;
    padding: 20px 0 0; }
  .default-popup .close {
    right: 6px; }
  .default-popup .action-box {
    padding: 20px 10px 10px; }
  .table-price-box .bottom-meta .summa {
    text-align: center;
    padding-right: 0; }
  .table-price-box .bottom-meta .book-now-bt {
    width: 50%; }
  .table-price-box .bottom-meta .summa {
    font-size: 40px; }
  .addons-popup {
    padding: 10px; }
  .breadcrumbs .back {
    margin-bottom: 15px;
    display: inline-block; }
  .breadcrumbs {
    padding: 0;
    margin-bottom: 11px; }
  .usluga-price-box .title {
    padding: 15px 5px 16px;
    font-size: 24px; } }

@media (max-width: 500px) {
  .main-info p br {
    display: none; } }

@media (max-width: 480px) {
  .header .lang-select {
    margin-top: 0;
    float: none;
    text-align: center;
    line-height: normal;
    margin-bottom: 20px; }
  .header .lang-select .lang-tab {
    display: inline-block;
    float: none;
    vertical-align: top; }
  .header .lang-select .ru-link,
  .header .lang-select .eng-link {
    display: inline-block;
    float: none;
    vertical-align: top; }
  .header .phone {
    float: none;
    text-align: center;
    margin-right: 0; }
  .header .phone a {
    font-size: 20px;
    line-height: normal; }
  .header .menu {
    float: right;
    margin-right: 10px; }
  .section-2 .title,
  .section-3 .title,
  .section-4 .title,
  .section-5 .title {
    font-size: 24px; }
  .section-2 .intro {
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto 35px; }
  .section-2 .intro h2 {
    padding-bottom: 17px;
    font-size: 24px; }
  .section-4 .title {
    margin-bottom: 30px; }
  #owl-carousel-1 .item-box p.text {
    border-right: 0;
    padding-right: 0;
    padding-bottom: 15px; }
  #owl-carousel-1 .item-box .big-pic {
    padding-bottom: 15px; }
  #owl-carousel-1 .item-box p.text span {
    height: auto; }
  #owl-carousel-1 .item-box .man-face-box span {
    height: auto; }
  .section-6 .title {
    font-size: 22px; }
  .section-6 .title.icon-instagram::before {
    font-size: 22px;
    margin-right: 10px; }
  .call-back-box p {
    padding: 0 10px 48px; }
  .call-back-box .call-back-bt {
    width: 90%; }
  .foot-right {
    padding-top: 26px; }
  .foot-left .copy {
    font-size: 12px; }
  .foot-right .phone {
    font-size: 14px;
    line-height: 35px; }
  .section-2-prog-opis .title,
  .section-2-prog-opis h2,
  .section-3-price-show-box .title,
  .section-3-price-show-box h2,
  .additional-services-box .title {
    font-size: 24px; }
  .additional-services-box {
    padding: 35px 0 55px 0; }
  .additional-services-box p {
    font-size: 18px;
    line-height: 22px; }
  .table-price-box table thead tr th {
    padding: 0 5px; }
  .table-price-box table tbody tr td {
    padding: 5px; }
  .table-price-box table thead {
    font-family: 'clear_sansregular';
    font-size: 12px; }
  .table-price-box table tbody {
    font-size: 12px; }
  .table-price-box .bottom-meta .book-now-bt {
    width: 100%; }
  .table-price-box .bottom-meta .summa {
    padding-bottom: 10px; }
  .table-price-box .bottom-meta {
    padding: 30px 0; }
  .more-about-body .post img {
    padding-bottom: 15px; }
  .news-page-list .post .post-title {
    font-size: 20px;
    line-height: 24px; }
  .contact-left-col .contact-box-bt {
    padding: 0 20px;
    width: auto; }
  .breadcrumbs .alignright {
    text-align: left; }
  .breadcrumbs .next {
    text-align: right;
    float: right; }
  .breadcrumbs .amp {
    display: none; }
  .trailer-popup .share-box > span {
    display: block; }
  .news-page-list .post .pic {
    overflow: hidden; }
  .news-page-list .post .pic img {
    min-height: 200px;
    width: auto; }
  .programm-box .text .more {
    opacity: 1;
    height: 50px;
    margin-bottom: 30px; }
  .programm-box:hover .text .opis {
    opacity: 0;
    height: 0;
    margin-bottom: 0; }
  .programm-box:hover:after {
    background: rgba(255, 255, 255, 0);
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7)); }
  .legal-information .pic {
    margin-bottom: 20px; }
  .legal-information .name {
    font-size: 15px; }
  .section-2 .intro span {
    font-size: 16px; }
  .section-1-programm {
    padding-top: 60px; }
  .section-1-programm .top-programm-links {
    padding-bottom: 40px; }
  .section-1-programm .inset-top-info .title,
  .section-1-programm .inset-top-info h1 {
    font-size: 35px;
    line-height: 35px; }
  .section-1-programm {
    min-height: inherit; }
  .section-2-prog-opis .title,
  .section-2-prog-opis h2 {
    padding-left: 10px; }
  .section-2-prog-opis .programm-opis-text {
    padding: 0 10px; }
  .section-1-uslugi-page .inset-top-info .title,
  .section-1-uslugi-page .inset-top-info h1 {
    font-size: 30px;
    line-height: 35px; }
  .section-1-uslugi-page .services-box li a i {
    font-size: 10px; }
  .briefly-about-project .title,
  .legal-information .title,
  .thanks-slider-box .title,
  .briefly-about-project h1,
  .legal-information h1,
  .thanks-slider-box h1,
  .briefly-about-project h2,
  .legal-information h2,
  .thanks-slider-box h2,
  .briefly-about-project h3,
  .legal-information h3,
  .thanks-slider-box h3 {
    font-family: 'intro_regular';
    font-size: 24px;
    line-height: 30px;
    color: #d1c391;
    margin: 0;
    font-weight: normal; }
  .briefly-about-project {
    padding-top: 39px; }
  .legal-information p {
    padding-left: 0; }
  .legal-information {
    padding: 20px 0; }
  .thanks-slider-box {
    padding: 40px 0; }
  .more-about-body .title,
  .more-about-body h1,
  .more-about-body h2,
  .more-about-body h3,
  .more-about-body h4 {
    font-size: 30px;
    padding-bottom: 28px; } }

@media (max-width: 400px) {
  .breadcrumbs .back span i,
  .breadcrumbs .next span i {
    display: none; } }

body#tinymce {
  margin: 12px !important; }
