// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;

$grid-gutter-width:         0 !default;